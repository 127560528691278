<template>
  <backdrop-blur :is-full-width="true">
    <div class="primary_layout full_width">
      <header-navigation />
      <page-content>
        <template v-slot:main>
          <v-row
            justify="center"
            align="center"
            style="height: calc(100vh - 160px); margin-top: -20px"
          >
            <v-col class="main_container">
              <v-container fill-height>
                <router-view></router-view>
              </v-container>
            </v-col>
          </v-row>
        </template>
      </page-content>
    </div>
  </backdrop-blur>
</template>

<script>
import BackdropBlur from "./BackdropBlur.vue";
import HeaderNavigation from "./navigation/NavigationMobile";
import PageContent from "@/components/PageContent";

export default {
  components: {
    HeaderNavigation,
    BackdropBlur,
    PageContent,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "./primaryLayout.scss";
.primary_layout.full_width {
  margin-top: 10px;
  .container {
    max-width: 100% !important;
  }
  .main_content {
    height: calc(100vh - 60px) !important;
  }
}
</style>
